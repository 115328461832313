<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Add Campaign Templates</div>
        <div class="
                        col-lg-7 col-12
                        text-end
                        d-flex
                        justify-content-end
                        custom-flex-cloumn-mob
                      ">
            <button type="button" @click="addcampignModalOpen()" class="header-btn-outer btn btn-primary">
                Add Campaign Template
            </button>
        </div>
    </div>
    <div class="custom-ultima-datatable">
        <DataTable :value="CreatedCampaignList" :scrollable="true" scrollHeight="flex" columnResizeMode="fit" :paginator="true" :lazy="true"
            :rowHover="true" :totalRecords="totalRecords" :rows="30" dataKey="aj1" @page="changePage($event)"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading">
            <template #empty>No Records found.</template>
            <template #loading>Loading Campaign data. Please wait...</template>
            <Column field="Name" header="Campaign Name" headerStyle="width: 30%" bodyStyle="width: 30%">
                <template #body="{ data }">
                    <div>{{ data.aj2 ? data.aj2 : "N/A" }}</div>
                </template>
            </Column>
            <Column field="message" header="Campaign Message" headerStyle="width: 70%" bodyStyle="width: 70%">
                <template #body="{ data }">
                    <div>{{ data.aj4 ? data.aj4 : "N/A" }}</div>
                </template>
            </Column>
        </DataTable>
    </div>
    <!-- Add New Campign modal start here -->
    <div class="modal-mask" v-if="addcampaignmodalsflag">
        <div class="modal-dialog modal-md modal-dialog-scrollable modal-dialog-centered
            custom-modal-outer">
            <div class="modal-content vh-100">
                <div class="modal-header whatsapp-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Add Campaign Template</h5>
                    <button type="button" class="btn-close" @click="addcampignModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Campaign Name <span class="text-danger">*</span></label>
                                <input type="text" v-model="campaignname" class="form-control"
                                    placeholder="Enter Campaign Name" maxlength="50" autocomplete="off" />
                                <div class="custom-error" v-if="v$.campaignname.$error">
                                    {{ v$.campaignname.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Facebook Template Name <span class="text-danger">*</span></label>
                                <input type="text" v-model="fbtemplatename" class="form-control"
                                    placeholder="Enter Campaign Name" maxlength="50" autocomplete="off" />
                                <div class="custom-error" v-if="v$.fbtemplatename.$error">
                                    {{ v$.fbtemplatename.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label for="formmessageInput" class="form-label">Campaign Message <span
                                        class="text-danger">*</span></label>
                                <textarea type="text" v-model="campaignmessage" class="form-control" id="formmessageInput"
                                    placeholder="Enter Campaign Message" autocomplete="off" rows="6"></textarea>
                                <div class="custom-error" v-if="v$.campaignmessage.$error">
                                    {{ v$.campaignmessage.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">First Button Name</label>
                                <input type="text" v-model="firstbtnname" class="form-control"
                                    placeholder="Enter First Button Name" maxlength="30" autocomplete="off" />
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Second Button Name</label>
                                <input type="text" v-model="secondbtnname" class="form-control"
                                    placeholder="Enter Second Button Name" maxlength="30" autocomplete="off" />
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Third Button Name</label>
                                <input type="text" v-model="thirdbtnname" class="form-control"
                                    placeholder="Enter Third Button Name" maxlength="30" autocomplete="off" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Select Media</label>
                                <FileUpload mode="basic" ref="mediafile" class="custom-upload-btn-ultima"
                                    v-model="mediafile" v-on:change="handleUploadMediaFile()" />
                                <span class="custom-error" v-if="msgtune.file">
                                    {{ msgtune.file }}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Select Language</label>
                                <div class="custom-group-radio-box-btn">
                                    <input type="radio" class="btn-check" v-model="languagebtn" name="language-option" id="englishbtn"
                                        autocomplete="off" value="1" />
                                    <label class="btn custom-radio-box-btn text-capitalize" for="englishbtn">English</label>
                                    <input type="radio" class="btn-check" v-model="languagebtn" name="language-option" id="marathibtn"
                                        autocomplete="off" value="2" />
                                    <label class="btn custom-radio-box-btn text-capitalize" for="marathibtn">Marathi</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 text-end">
                        <button type="button" class="btn modal-submit-btn" @click="createcampaigntemplate()">
                            <span v-if="!modalloader">Submit</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="modalloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add New Campign modal end here -->
</template>
<script>
import ApiService from "../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
    data() {
        return {
            v$: useValidate(),
            CreatedCampaignList: null,
            totalRecords: 0,
            loading: false,
            addcampaignmodalsflag: false,
            campaignname: "",
            fbtemplatename: "",
            campaignmessage: "",
            mediafile: "",
            firstbtnname: "",
            secondbtnname: "",
            thirdbtnname: "",
            languagebtn: 1,
            msgtune: [],
            modalloader: false,
        };
    },
    validations() {
        return {
            campaignname: {
                required: helpers.withMessage("Please enter campaign name", required),
            },
            fbtemplatename: {
                required: helpers.withMessage("Please enter facebook template name", required),
            },
            campaignmessage: { required: helpers.withMessage("Please enter campaign message", required) },
            //   mediafile: {
            //     required: helpers.withMessage("Please select media file", required),
            //   },
        };
    },
    ApiService: null,

    created() {
        this.ApiService = new ApiService();
    },

    mounted() {
        this.loading = true;
        this.getcampaigntemplates();
    },

    methods: {
        getcampaigntemplates(item) {
            this.ApiService.getcampaigntemplates(item).then((data) => {
                if (data.success === true) {
                    this.CreatedCampaignList = data.records;
                    this.totalRecords = data.totalcount;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.CreatedCampaignList = null;
                    this.totalRecords = 0;
                }
            });
        },
        changePage(event) {
            this.page_no = event.page;
            this.getcampaigntemplates({ page_no: this.page_no });
        },
        addcampignModalOpen() {
            this.addcampaignmodalsflag = true;
        },
        addcampignModalClose() {
            this.addcampaignmodalsflag = false;
        },
        handleUploadMediaFile() {
            this.mediafile = this.$refs.mediafile.files[0];
            var file = this.$refs.mediafile.files[0];
            if (!file) {
                this.msgtune["file"] = "Please upload file.";
            } else {
                this.msgtune["file"] = "";
            }
        },
        createcampaigntemplate() {
            this.v$.$validate();
            var formData = new FormData();
            formData.append("template_alias_name", this.campaignname);
            formData.append("fb_template_name", this.fbtemplatename);
            formData.append("template_body", this.campaignmessage);
            formData.append("first_button", this.firstbtnname);
            formData.append("second_button", this.secondbtnname);
            formData.append("third_button", this.thirdbtnname);
            formData.append("language", this.languagebtn);
            if (this.mediafile) {
                formData.append("media_file", this.mediafile);
            }
            if (!this.v$.$error) {
                this.modalloader = true;
                this.ApiService.storecampaigntemplate(formData).then((data) => {
                    if (data.status == 200) {
                        this.modalloader = false;
                        // var successMsg = data.message;
                        this.addcampaignmodalsflag = false;
                        // this.$toast.open({
                        //     message: successMsg,
                        //     type: "success",
                        //     duration: 3000,
                        //     position: "top-right",
                        // });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.getcampaigntemplates();
                        this.campaignname = '';
                        this.fbtemplatename = '';
                        this.campaignmessage = '';
                        this.firstbtnname = '';
                        this.secondbtnname = '';
                        this.thirdbtnname = '';
                        this.languagebtn = 1;
                        this.mediafile = '';
                    } 
                    else {
                        this.modalloader = false;
                        this.addcampaignmodalsflag = false;
                        this.getcampaigntemplates();
                        // var errorMsg = data.message;
                        // this.$toast.open({
                        //     message: errorMsg,
                        //     type: "error",
                        //     duration: 3000,
                        //     position: "top-right",
                        // });
                    }
                });
            }
        }
    },
};
</script>
<style scoped>
.modal-submit-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
}

.modal-submit-btn:focus {
    box-shadow: none;
}
</style>